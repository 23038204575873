<template>
  <el-main class="rh-form">
    <form-theseedit :routetolist="{ name: 'theses' }" />
  </el-main>
</template>
<script>
import FormTheseedit from "@/components/rh/formulaires/FormTheseedit";

export default {
  components: { "form-theseedit": FormTheseedit },
};
</script>
